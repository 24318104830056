import { render, staticRenderFns } from "./books.vue?vue&type=template&id=c3c6e5e6"
import script from "./books.vue?vue&type=script&lang=js"
export * from "./books.vue?vue&type=script&lang=js"
import style0 from "vue-multiselect/dist/vue-multiselect.min.css?vue&type=style&index=0&prod&lang=css&external"
import style1 from "./books.vue?vue&type=style&index=1&id=c3c6e5e6&prod&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports