import Vue from 'vue'
import Router from "vue-router";
// import VueMeta from 'vue-meta';
import store from './store';

Vue.use(Router)
// Vue.use(VueMeta)



const router = new Router({
  mode: "history",
  scrollBehavior (to, from, savedPosition) {
    to
    from 
    savedPosition
    return new Promise((resolve, reject) => {
      reject
      setTimeout(() => {
        resolve({ x: 0, y: 0 })
      }, 300)
    })
  },
  
    routes: [
    
      {
        path: '/auth',
        // name:'auth',
        component: () => import('./views/pages/Index.vue'),
        children: [{
          name: 'Login',
          path: '',
          component: () => import('./views/pages/Login.vue')
        }]
      },
      {
        path: '/',
        component: () => import('./views/dashboard/Index.vue'),
        beforeEnter: (to, from, next) => {
          
          if (localStorage.getItem('tokinn') && store.state.AdminInfo.authe==true) {
            next()
          } else {
            next({
              name: 'Login'
            });
            next()
            router.push({ path: '/auth' });
          }
        },   
        children: [
          // {
          //   name: 'الاحصاءات',
          //   path: 'stats',
          //   component: () => import('./views/dashboard/chart.vue')
          // },

     
          // {
          //   path:'applicant',
          //   name:'المتقدمين على الوظيفة',
          //   component: () => import('./views/dashboard/applicant.vue'),
          // },
         
          { path: '/writers', component: require('./components/writers.vue').default },
          { path: '/books', component: require('./components/books.vue').default },
          { path: '/contactus', component: require('./components/contactus.vue').default },
          { path: '/exl', component: require('./components/exl.vue').default },
          { path: '/info', component: require('./components/info.vue').default },
          { path: '/example', component: require('./views/dashboard/example.vue').default },
 
        ]   
        
      },
    
    
    
       
       
    ]
    });
    
    
    
    
    router.beforeEach((to, from, next) => {
     
        window.document.title = to.name ;
   
      next();
    });
    
    export default router;

