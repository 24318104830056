<template>
  <!-- <div>{{ message }}</div> -->
  <book-view></book-view>
</template>

<script>
  import BookView from '../../components/books.vue';

export default {
  name:'ExampleView',
  props: {
    message: String
  },
  components: {
    BookView
  },
}
</script>
