import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
import VueAxios from "vue-axios";
import router from './router'

import createPersistedState from 'vuex-persistedstate'
Vue.use(Vuex)
Vue.use(VueAxios, axios);
import VueI18n from 'vue-i18n'
Vue.use(VueI18n)

export const i18n = new VueI18n({
  locale: localStorage.getItem("lang") || 'ar',
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'ar',
})


export default new Vuex.Store({
  state: {
    
    barColor: '#272727',
    lang:'ar',
    art_uploude:{},
    role:'',
    direction:'rtl',
    idToken:localStorage.getItem('tokinn'),
    AdminInfo:{
        id:'',
        token:'',
        authe:false,
        name:'',
        email:'',
        photo:''

    },
    social: '',
    navigation: '',
    prayer: '',
    date: ''
  },
  plugins: [
    createPersistedState({     
    })
  ],
  mutations: {
  
    
      library_uploads(state, library) {
        console.log(state);
        state.library_uploads=library;
      },

    UpdateLang(state, UpdateLang) {
     
      state.lang= UpdateLang.lang
      state.direction= UpdateLang.dir
    },

    vid_uploads(state, vids) {
      console.log(state);
      state.vid_uploads=vids;
    },
    book_uploads(state, books) {
      console.log(state);
      state.book_uploads=books
    },

    art_uploudes(state,arts) {
      console.log(arts);
      state.art_uploude=arts
    },

    setRole(state,role) {
      state.role = role
     
    },

    clearAuth(state) {
        state.AdminInfo={
          id:'',
          token:'',
          authe:false,
          name:'',
          email:'',
          photo:''
        }
      } ,

    authUser(state, userData) {
        state.AdminInfo.id = userData.id
        state.AdminInfo.name = userData.name
        state.AdminInfo.authe=true
        state.AdminInfo.email = userData.email
        state.AdminInfo.photo = userData.photo
        state.AdminInfo.token = userData.token
  
      },

      dirChange(state,dir)
      {
        state.direction=dir;

      },
  
      addSocial (state, arr) {
        state.social = arr
      }, 

      addNavigation (state, arr) {
        state.navigation = arr
      }, 

      addPrayer (state, arr) {
        state.prayer = arr
      }, 

      addDate (state, arr) {
        state.date = arr
      }, 
    
  },
  actions: {

    library_uploads({commit}, library) {
      console.log(library);
      commit('library_uploads',library)},

    video_uploads({commit}, vids) {
      console.log(vids);
      commit('vid_uploads',vids)},

      book_uploads({commit}, books) {
        console.log(books);
        commit('book_uploads',books)},


    art_uploudess({
      commit
    }, arts){

     console.log(arts);
      commit('art_uploudes',arts);
    
    
  
    },


    changeDir({
      commit
    }, dir) 
    {
  
    
      commit('dirChange',dir);
   
     
    },


    
    login({
        commit
      }, userData) {
  
        
      
        commit('authUser', {
          token:localStorage.getItem('tokinn'),
          userId: userData.id,
          name: userData.name,
          policyNumber: userData.photp,
          userPhotoUrl: userData.email,

        });
      
        let authenticate = Promise.resolve({ role: "admin" });
        authenticate.then(user => { 
          // Vue.prototype.$user.set(user);
        })

        commit('setRole','admin');
        router.push("/books")
    
      },

      logout({
        commit
      }) {
        commit('clearAuth')
        localStorage.clear();
        //localStorage.setItem("darkMode", this.$vuetify.theme.dark);
        //this.$i18n.locale = this.$i18n.locale == "ar" ? "ar" : "en";
        //localStorage.setItem("lang", this.$i18n.locale);
        //this.$vuetify.rtl = localStorage.getItem("lang") == "en" ? true : false;
        axios.defaults.headers.get["Authorization"] = null;
  
      },


UpdateLang({
  commit
}, lang) {


  commit('UpdateLang', {
    lang:lang.lang,
    dir:lang.dir,

  });
  //loadLanguageAsync(lang); 
//   require('/public/assets/css/langs/'+lang+'.css');
//  loadLanguageAsync(lang);
//  .
 
//  then(() => next())
//   router.beforeEach((to, from, next) => {
   
 
 
//     loadLanguageAsync(lang).then(() => next())
//   })

},
   

   
  }
})


const loadedLanguages = []

function setI18nLanguage (lang) {
  i18n.locale = lang
  axios.defaults.headers.common['Accept-Language'] = lang
  document.querySelector('html').setAttribute('lang', lang)
  return lang
}

export function loadLanguageAsync (lang) {
  if (loadedLanguages.includes(lang)) {
    router.push({ path: '/?lang='+lang+'' })
    // require('/public/assets/css/langs/'+lang+'.css');
    if (i18n.locale !== lang) setI18nLanguage(lang)
    return Promise.resolve()
  }

    router.push({ path: '/?lang='+lang+'' })
    // require('/public/assets/css/langs/'+lang+'.css');
  return  axios.get(`lang/getWord/${lang}`)

  
  .then(response => {
   
    let msgs = response.data
    console.log(msgs);
    loadedLanguages.push(lang)
    i18n.setLocaleMessage(lang, msgs)
    setI18nLanguage(lang)
  


    router.push({ path: '/?lang='+lang+'' })
    
  
  })
}
